<template lang="pug">
  include ../../assets/pug/index

  div
    +h2('title.testingCenterStands').text-center.m-5
    div
      .mb-7.d-flex.justify-end
        v-btn.btn-primary.mx-2(@click="viewForm = !viewForm")
          span
            v-icon {{ viewForm ? 'mdi-close' : 'mdi-plus' }}
            span {{ $t('btn.addStand') }}
      .d-flex.w-50.p-2.mb-5(
        v-if="viewForm"
        style="margin: 0 auto; border: 1px solid #ccc"
      )
        +autocomplete('addStand.id', 'standUnreg', 'stand_info[0].stand_name', 'id', 'choiceStand', '["required"]')
        +btn('primary', 'btn.add')(
          v-if="viewForm"
          @click="validation()"
          :loading="isLoad"
        ).mt-3.ml-6
    Table(
      :items="tcObj.stand"
      :headers="headers"
      :swal="openSwal"
    )
</template>

<script>
import { validate, validationField } from '@/mixins/validation'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'testingCenterStands',
  props: {
    tcObj: Object
  },
  data () {
    return {
      viewForm: false,
      isLoad: false,
      addStand: {
        id: '',
      },
      links: [
        {
          name: 'testingCenterInfo',
          icon: 'mdi-information-outline'
        },
      ],
      headers: [
        { text: 'ID', value: 'id'},
        { text: this.$i18n.t('nameStand'), value: 'name'},
        { text: this.$i18n.t('status.status'), value: 'status'},
        { text: 'IP', value: 'last_ip'},
        { text: this.$i18n.t('action'), value: 'actionsFunction'}
      ],
      validationField
    }
  },
  validations: validate.addStand,
  computed: {
    ...mapState({
      standUnreg: state => state.stand.standUnreg
    })
  },
  methods: {
    ...mapActions(['setTestingCenterStand', 'getUnregStand', 'removeStand', 'setStandData']),
    validationFieldsRequired(field) {
      return this.$validate.required(field)
    },
    validation () {
      this.$v.$touch()
      !this.$v.$invalid && this.setParamfForSetStand()
    },
    configStandParam () {
      let body = new URLSearchParams()
      body.append('stand_id', this.addStand.id)
      body.append('testing_center_id', this.tcObj.id)
      return body
    },
    resetStand () {
      this.addStand.id = ""
      this.isLoad = false
      this.$v.$reset()
    },
    setParamfForSetStand () {
      this.isLoad = true
      let body = this.configStandParam()
      this.setTestingCenterStand(body).then(() => this.resetStand())
    },
    openSwal(id) {
      this.$swal({
        title: this.$i18n.t('title.sureRemoveStand'),
        icon: 'warning',
        buttons: [this.$i18n.t('btn.cancel'), this.$i18n.t('btn.yes')]
      })
      .then(removeStand => removeStand && this.removeStand(id))
    }
  }
}
</script>
