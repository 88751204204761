<template lang="pug">
  v-card.ma-6
    TestingCenterForm(:setObject="tcObj" pageInfo)
    TestingCenterStands(:tcObj="tcObj")
</template>

<script>
import { mapActions } from 'vuex'

import TestingCenterForm from './TestingCenterForm'
import TestingCenterStands from './TestingCenterStands'

export default {
  name: 'testingCenterInfo',
  components: {
    TestingCenterForm,
    TestingCenterStands
  },
  data () {
    return {
      id: Number(this.$route.params.id),
    }
  },
  computed: {
    tcObj () {
      let tcObj = this.$store.getters.testingCenterById(this.id)
      if (!tcObj.length) this.setStandData()
      return tcObj[0]
    }
  },
  methods: {
    ...mapActions(['setStandData'])
  }
}
</script>
